@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}

@layer utilities {
  .text-initial {
    text-align: initial;
  }
}

/**
 * NB! If you make changes to this file, you need to make the same change in
 * applications/storybook/globals.css until we have a better solution for this.
 */

/*
 * All the themes below will be included in the final bundle.
 * Once we are scanning all applications for Tailwind we can wrap the themes
 * with the @ layer base directive. This will make sure that the unused themes
 * are not included in the final bundle.
 */

:root {
  @apply light pixel-font-size-line-height;

  /* Font-family */
  --font-family-body: MentiText, Arial, sans-serif;
  --font-family-heading: MentiDisplay, Arial, sans-serif;
}

/* Font size and Line height in rem */
.rem-font-size-line-height {
  --font-size-62-5: 0.625rem;
  --font-size-75: 0.75rem;
  --font-size-87-5: 0.875rem;
  --font-size-100: 1rem;
  --font-size-112-5: 1.125rem;
  --font-size-125: 1.25rem;
  --font-size-150: 1.5rem;
  --font-size-175: 1.75rem;
  --font-size-200: 2rem;
  --font-size-225: 2.25rem;
  --font-size-250: 2.5rem;
  --font-size-300: 3rem;
  --font-size-400: 4rem;
  --font-size-500: 5rem;
  --font-size-600: 6rem;

  --line-height-75: 0.75rem;
  --line-height-100: 1rem;
  --line-height-125: 1.25rem;
  --line-height-150: 1.5rem;
  --line-height-175: 1.75rem;
  --line-height-200: 2rem;
  --line-height-225: 2.25rem;
  --line-height-250: 2.5rem;
}

/* Font size and Line height in px */
.pixel-font-size-line-height {
  --font-size-62-5: 10px;
  --font-size-75: 12px;
  --font-size-87-5: 14px;
  --font-size-100: 16px;
  --font-size-112-5: 18px;
  --font-size-125: 20px;
  --font-size-150: 24px;
  --font-size-175: 28px;
  --font-size-200: 32px;
  --font-size-225: 36px;
  --font-size-250: 40px;
  --font-size-300: 48px;
  --font-size-400: 64px;
  --font-size-500: 80px;
  --font-size-600: 96px;

  --line-height-75: 12px;
  --line-height-100: 16px;
  --line-height-125: 20px;
  --line-height-150: 24px;
  --line-height-175: 28px;
  --line-height-200: 32px;
  --line-height-225: 36px;
  --line-height-250: 40px;
}

.light {
  /* Palette */
  --palette-black-alpha-100: rgb(0 0 0 / 0.05);
  --palette-black-alpha-1000: rgb(0 0 0 / 0.85);
  --palette-black-alpha-200: rgb(0 0 0 / 0.1);
  --palette-black-alpha-300: rgb(0 0 0 / 0.14);
  --palette-black-alpha-400: rgb(0 0 0 / 0.2);
  --palette-black-alpha-50: rgb(0 0 0 / 0.02);
  --palette-black-alpha-500: rgb(0 0 0 / 0.3);
  --palette-black-alpha-600: rgb(0 0 0 / 0.45);
  --palette-black-alpha-700: rgb(0 0 0 / 0.55);
  --palette-black-alpha-800: rgb(0 0 0 / 0.65);
  --palette-black-alpha-900: rgb(0 0 0 / 0.75);
  --palette-black-base: rgb(0 0 0 / 1);
  --palette-blue-100: rgb(213 218 247 / 1);
  --palette-blue-200: rgb(169 181 252 / 1);
  --palette-blue-300: rgb(138 155 255 / 1);
  --palette-blue-400: rgb(112 129 243 / 1);
  --palette-blue-50: rgb(229 233 255 / 1);
  --palette-blue-500: rgb(87 105 231 / 1);
  --palette-blue-600: rgb(73 88 190 / 1);
  --palette-blue-700: rgb(63 75 161 / 1);
  --palette-blue-800: rgb(50 60 124 / 1);
  --palette-blue-alpha-100: rgb(87 105 231 / 0.35);
  --palette-blue-alpha-50: rgb(87 105 231 / 0.25);
  --palette-blue-alpha-500: rgb(87 105 231 / 0.5);
  --palette-blue-alpha-700: rgb(87 105 231 / 0.6);
  --palette-blue-alpha-900: rgb(87 105 231 / 0.9);
  --palette-blue-base: rgb(87 105 231 / 1);
  --palette-gray-100: rgb(247 246 244 / 1);
  --palette-gray-1000: rgb(71 69 67 / 1);
  --palette-gray-1050: rgb(54 52 50 / 1);
  --palette-gray-1100: rgb(48 46 44 / 1);
  --palette-gray-1200: rgb(28 27 25 / 1);
  --palette-gray-1300: rgb(23 23 23 / 1);
  --palette-gray-1400: rgb(16 16 16 / 1);
  --palette-gray-200: rgb(242 241 240 / 1);
  --palette-gray-300: rgb(235 234 232 / 1);
  --palette-gray-400: rgb(232 230 227 / 1);
  --palette-gray-50: rgb(252 251 249 / 1);
  --palette-gray-500: rgb(222 220 217 / 1);
  --palette-gray-600: rgb(197 195 193 / 1);
  --palette-gray-700: rgb(160 159 157 / 1);
  --palette-gray-800: rgb(116 115 113 / 1);
  --palette-gray-900: rgb(93 91 89 / 1);
  --palette-gray-base: rgb(222 220 217 / 1);
  --palette-green-100: rgb(228 244 231 / 1);
  --palette-green-200: rgb(202 232 206 / 1);
  --palette-green-300: rgb(160 213 176 / 1);
  --palette-green-400: rgb(111 185 134 / 1);
  --palette-green-50: rgb(240 250 242 / 1);
  --palette-green-500: rgb(82 173 110 / 1);
  --palette-green-600: rgb(59 133 82 / 1);
  --palette-green-700: rgb(47 102 65 / 1);
  --palette-green-800: rgb(37 62 45 / 1);
  --palette-green-base: rgb(82 173 110 / 1);
  --palette-neutral-alpha-100: rgb(242 241 240 / 0.4);
  --palette-neutral-alpha-50: rgb(242 241 240 / 0.25);
  --palette-neutral-alpha-500: rgb(242 241 240 / 0.5);
  --palette-neutral-alpha-700: rgb(242 241 240 / 0.6);
  --palette-neutral-alpha-900: rgb(242 241 240 / 0.95);
  --palette-coral-100: rgb(255 222 221 / 1);
  --palette-coral-200: rgb(255 192 190 / 1);
  --palette-coral-300: rgb(255 166 164 / 1);
  --palette-coral-400: rgb(255 139 137 / 1);
  --palette-coral-50: rgb(255 243 242 / 1);
  --palette-coral-500: rgb(255 116 113 / 1);
  --palette-coral-600: rgb(199 78 76 / 1);
  --palette-coral-700: rgb(169 60 57 / 1);
  --palette-coral-800: rgb(128 46 45 / 1);
  --palette-coral-base: rgb(255 116 113 / 1);
  --palette-purple-100: rgb(238 239 253 / 1);
  --palette-purple-200: rgb(225 226 250 / 1);
  --palette-purple-300: rgb(188 188 243 / 1);
  --palette-purple-400: rgb(167 167 239 / 1);
  --palette-purple-50: rgb(248 248 254 / 1);
  --palette-purple-500: rgb(142 142 234 / 1);
  --palette-purple-600: rgb(108 103 207 / 1);
  --palette-purple-700: rgb(94 89 179 / 1);
  --palette-purple-800: rgb(64 52 109 / 1);
  --palette-purple-base: rgb(142 142 234 / 1);
  --palette-purple-light-alpha-100: rgb(142 142 234 / 0.35);
  --palette-purple-light-alpha-50: rgb(142 142 234 / 0.25);
  --palette-purple-light-alpha-500: rgb(142 142 234 / 0.5);
  --palette-purple-light-alpha-700: rgb(142 142 234 / 0.6);
  --palette-purple-light-alpha-900: rgb(142 142 234 / 0.9);
  --palette-sand-100: rgb(243 237 231 / 1);
  --palette-sand-200: rgb(221 214 207 / 1);
  --palette-sand-300: rgb(204 197 189 / 1);
  --palette-sand-400: rgb(183 175 167 / 1);
  --palette-sand-50: rgb(252 247 242 / 1);
  --palette-sand-500: rgb(165 157 148 / 1);
  --palette-sand-600: rgb(141 134 126 / 1);
  --palette-sand-700: rgb(119 113 106 / 1);
  --palette-sand-800: rgb(97 91 85 / 1);
  --palette-sand-base: rgb(165 157 148 / 1);
  --palette-white-alpha-100: rgb(255 255 255 / 0.05);
  --palette-white-alpha-1000: rgb(255 255 255 / 0.85);
  --palette-white-alpha-200: rgb(255 255 255 / 0.1);
  --palette-white-alpha-300: rgb(255 255 255 / 0.14);
  --palette-white-alpha-400: rgb(255 255 255 / 0.2);
  --palette-white-alpha-50: rgb(255 255 255 / 0.02);
  --palette-white-alpha-500: rgb(255 255 255 / 0.3);
  --palette-white-alpha-600: rgb(255 255 255 / 0.45);
  --palette-white-alpha-700: rgb(255 255 255 / 0.55);
  --palette-white-alpha-800: rgb(255 255 255 / 0.65);
  --palette-white-alpha-900: rgb(255 255 255 / 0.75);
  --palette-white-base: rgb(255 255 255 / 1);
  --palette-yellow-100: rgb(255 244 215 / 1);
  --palette-yellow-200: rgb(255 227 155 / 1);
  --palette-yellow-300: rgb(255 219 126 / 1);
  --palette-yellow-400: rgb(255 213 106 / 1);
  --palette-yellow-50: rgb(255 251 241 / 1);
  --palette-yellow-500: rgb(255 199 56 / 1);
  --palette-yellow-600: rgb(219 171 48 / 1);
  --palette-yellow-700: rgb(178 134 21 / 1);
  --palette-yellow-800: rgb(128 100 28 / 1);
  --palette-yellow-base: rgb(255 199 56 / 1);

  /* Color tokens */
  --color-bg: var(--palette-white-base);
  --color-bg-strong: var(--palette-gray-300);
  --color-bg-overlay: var(--palette-black-alpha-700);
  --color-border: var(--palette-gray-500);
  --color-border-brand-weakest: var(--palette-blue-100);
  --color-border-disabled: var(--palette-gray-500);
  --color-border-info-weakest: var(--palette-purple-100);
  --color-border-neutral-weakest: var(--palette-gray-300);
  --color-border-negative-weakest: var(--palette-coral-100);
  --color-border-notice-weakest: var(--palette-yellow-100);
  --color-border-positive-weakest: var(--palette-green-100);
  --color-border-primary-weakest: var(--palette-gray-200);
  --color-border-secondary-weakest: var(--palette-purple-200);
  --color-border-strong: var(--palette-gray-600);
  --color-border-transparent: var(--palette-black-alpha-300);
  --color-border-weak: var(--palette-gray-300);
  --color-border-weaker: var(--palette-gray-200);
  --color-brand: var(--palette-blue-500);
  --color-brand-weak: var(--palette-blue-200);
  --color-brand-weakest: var(--palette-blue-50);
  --color-disabled: var(--palette-gray-100);
  --color-disabled-strong: var(--palette-gray-200);
  --color-high-contrast: var(--palette-black-base);
  --color-info: var(--palette-purple-700);
  --color-info-weak: var(--palette-purple-100);
  --color-info-weakest: var(--palette-purple-50);
  --color-interactive-focused: var(--palette-purple-light-alpha-500);
  --color-negative: var(--palette-coral-600);
  --color-negative-weak: var(--palette-coral-100);
  --color-negative-weakest: var(--palette-coral-50);
  --color-neutral: var(--palette-gray-400);
  --color-neutral-weak: var(--palette-gray-200);
  --color-neutral-weakest: var(--palette-gray-50);
  --color-notice: var(--palette-yellow-400);
  --color-notice-weak: var(--palette-yellow-200);
  --color-notice-weakest: var(--palette-yellow-100);
  --color-on-brand: var(--palette-white-base);
  --color-on-brand-weak: var(--palette-gray-1400);
  --color-on-brand-weakest: var(--palette-gray-1400);
  --color-on-disabled: var(--palette-gray-500);
  --color-on-disabled-strong: var(--palette-gray-600);
  --color-on-high-contrast: var(--palette-gray-100);
  --color-on-info: var(--palette-white-base);
  --color-on-info-weak: var(--palette-gray-1400);
  --color-on-info-weakest: var(--palette-gray-1400);
  --color-on-negative: var(--palette-white-base);
  --color-on-negative-weak: var(--palette-coral-800);
  --color-on-negative-weakest: var(--palette-coral-800);
  --color-on-neutral: var(--palette-gray-1100);
  --color-on-neutral-weak: var(--palette-gray-1100);
  --color-on-neutral-weakest: var(--palette-gray-1100);
  --color-on-notice: var(--palette-gray-1400);
  --color-on-notice-weak: var(--palette-gray-1400);
  --color-on-notice-weakest: var(--palette-gray-1400);
  --color-on-positive: var(--palette-white-base);
  --color-on-positive-weak: var(--palette-gray-1400);
  --color-on-positive-weakest: var(--palette-green-800);
  --color-on-primary: var(--palette-white-base);
  --color-on-primary-weak: var(--palette-gray-1100);
  --color-on-primary-weakest: var(--palette-gray-1100);
  --color-on-secondary: var(--palette-white-base);
  --color-on-secondary-weak: var(--palette-gray-1400);
  --color-on-secondary-weakest: var(--palette-gray-1400);
  --color-positive: var(--palette-green-600);
  --color-positive-weak: var(--palette-green-200);
  --color-positive-weakest: var(--palette-green-100);
  --color-primary: var(--palette-gray-1400);
  --color-primary-weak: var(--palette-gray-500);
  --color-primary-weakest: var(--palette-gray-200);
  --color-secondary: var(--palette-purple-700);
  --color-secondary-weak: var(--palette-purple-200);
  --color-secondary-weakest: var(--palette-purple-50);
  --color-surface: var(--palette-white-base);
  --color-surface-raised: var(--palette-white-base);
  --color-surface-sunken: var(--palette-gray-200);
  --color-surface-overlay: var(--palette-white-base);
  --color-surface-overlay-transparent: var(--palette-neutral-alpha-700);
  --color-surface-overlay-transparent-hover: var(--palette-neutral-alpha-900);
  --color-text: var(--palette-gray-1300);
  --color-text-disabled: var(--palette-gray-500);
  --color-text-link: var(--palette-gray-1100);
  --color-text-negative: var(--palette-coral-800);
  --color-text-primary: var(--palette-gray-1100);
  --color-text-weak: var(--palette-black-alpha-900);
  --color-text-weaker: var(--palette-black-alpha-700);
  --color-input-bg: var(--palette-gray-200);
  --color-input-bg-active: var(--palette-white-base);
  --color-button-bg-brand: var(--palette-blue-500);
  --color-button-bg-brand-active: var(--palette-blue-700);
  --color-button-bg-brand-hover: var(--palette-blue-600);
  --color-button-bg-negative: var(--palette-coral-600);
  --color-button-bg-negative-active: var(--palette-coral-800);
  --color-button-bg-negative-hover: var(--palette-coral-700);
  --color-button-bg-negative-weak: var(--palette-coral-50);
  --color-button-bg-negative-weak-active: var(--palette-coral-200);
  --color-button-bg-negative-weak-hover: var(--palette-coral-100);
  --color-button-bg-outline-active: var(--palette-gray-200);
  --color-button-bg-outline-hover: var(--palette-gray-100);
  --color-button-bg-outline-checked: var(--palette-purple-200);
  --color-button-bg-outline-checked-active: var(--palette-purple-300);
  --color-button-bg-outline-checked-hover: var(--palette-purple-200);
  --color-button-bg-positive: var(--palette-green-600);
  --color-button-bg-positive-active: var(--palette-green-800);
  --color-button-bg-positive-hover: var(--palette-green-700);
  --color-button-bg-primary: var(--palette-gray-1100);
  --color-button-bg-primary-active: var(--palette-gray-1400);
  --color-button-bg-primary-hover: var(--palette-gray-1300);
  --color-button-bg-primary-checked: var(--palette-purple-600);
  --color-button-bg-primary-checked-active: var(--palette-purple-800);
  --color-button-bg-primary-checked-hover: var(--palette-purple-700);
  --color-button-bg-primary-reverse: var(--palette-white-base);
  --color-button-bg-primary-reverse-active: var(--palette-gray-600);
  --color-button-bg-primary-reverse-hover: var(--palette-gray-400);
  --color-button-bg-primary-reverse-checked: var(--palette-purple-600);
  --color-button-bg-primary-reverse-checked-active: var(--palette-purple-800);
  --color-button-bg-primary-reverse-checked-hover: var(--palette-purple-700);
  --color-button-bg-secondary-hover: var(--palette-gray-200);
  --color-button-bg-secondary-active: var(--palette-gray-300);
  --color-button-bg-secondary-checked: var(--palette-purple-50);
  --color-button-bg-secondary-checked-hover: var(--palette-purple-100);
  --color-button-bg-secondary-checked-active: var(--palette-purple-200);
  --color-button-bg-tertiary: var(--palette-gray-200);
  --color-button-bg-tertiary-active: var(--palette-gray-600);
  --color-button-bg-tertiary-hover: var(--palette-gray-400);
  --color-button-bg-tertiary-checked: var(--palette-purple-600);
  --color-button-bg-tertiary-checked-active: var(--palette-purple-800);
  --color-button-bg-tertiary-checked-hover: var(--palette-purple-700);
  --color-surface-hover: var(--palette-gray-100);
  --color-surface-active: var(--palette-gray-200);
  --color-surface-checked: var(--palette-purple-50);
  --color-surface-checked-hover: var(--palette-purple-200);
}

.dark {
  --palette-black-alpha-100: rgb(0 0 0 / 0.05);
  --palette-black-alpha-1000: rgb(0 0 0 / 0.85);
  --palette-black-alpha-200: rgb(0 0 0 / 0.1);
  --palette-black-alpha-300: rgb(0 0 0 / 0.14);
  --palette-black-alpha-400: rgb(0 0 0 / 0.2);
  --palette-black-alpha-50: rgb(0 0 0 / 0.02);
  --palette-black-alpha-500: rgb(0 0 0 / 0.3);
  --palette-black-alpha-600: rgb(0 0 0 / 0.45);
  --palette-black-alpha-700: rgb(0 0 0 / 0.55);
  --palette-black-alpha-800: rgb(0 0 0 / 0.65);
  --palette-black-alpha-900: rgb(0 0 0 / 0.75);
  --palette-black-base: rgb(0 0 0 / 1);
  --palette-blue-100: rgb(33 46 72 / 1);
  --palette-blue-200: rgb(13 54 128 / 1);
  --palette-blue-300: rgb(18 76 178 / 1);
  --palette-blue-400: rgb(22 93 219 / 1);
  --palette-blue-50: rgb(34 38 49 / 1);
  --palette-blue-500: rgb(25 108 255 / 1);
  --palette-blue-600: rgb(83 145 255 / 1);
  --palette-blue-700: rgb(107 161 255 / 1);
  --palette-blue-800: rgb(141 175 235 / 1);
  --palette-blue-alpha-100: rgb(25 108 255 / 0.35);
  --palette-blue-alpha-50: rgb(25 108 255 / 0.25);
  --palette-blue-alpha-500: rgb(25 108 255 / 0.5);
  --palette-blue-alpha-700: rgb(25 108 255 / 0.6);
  --palette-blue-alpha-900: rgb(25 108 255 / 0.9);
  --palette-blue-base: rgb(25 108 255 / 1);
  --palette-blue-dark-100: rgb(31 32 39 / 1);
  --palette-blue-dark-200: rgb(8 12 26 / 1);
  --palette-blue-dark-300: rgb(11 17 36 / 1);
  --palette-blue-dark-400: rgb(14 21 45 / 1);
  --palette-blue-dark-50: rgb(33 33 37 / 1);
  --palette-blue-dark-500: rgb(19 26 49 / 1);
  --palette-blue-dark-600: rgb(76 82 103 / 1);
  --palette-blue-dark-700: rgb(90 95 111 / 1);
  --palette-blue-dark-800: rgb(137 140 152 / 1);
  --palette-blue-dark-alpha-100: rgb(15 24 52 / 0.35);
  --palette-blue-dark-alpha-50: rgb(15 24 52 / 0.25);
  --palette-blue-dark-alpha-500: rgb(15 24 52 / 0.5);
  --palette-blue-dark-alpha-700: rgb(15 24 52 / 0.75);
  --palette-blue-dark-alpha-900: rgb(15 24 52 / 0.9);
  --palette-blue-dark-base: rgb(19 26 49 / 1);
  --palette-blue-light-100: rgb(58 62 68 / 1);
  --palette-blue-light-200: rgb(90 102 115 / 1);
  --palette-blue-light-300: rgb(126 143 160 / 1);
  --palette-blue-light-400: rgb(142 159 178 / 1);
  --palette-blue-light-50: rgb(42 44 47 / 1);
  --palette-blue-light-500: rgb(154 173 194 / 1);
  --palette-blue-light-600: rgb(173 192 214 / 1);
  --palette-blue-light-700: rgb(206 222 238 / 1);
  --palette-blue-light-800: rgb(240 245 250 / 1);
  --palette-blue-light-base: rgb(154 173 194 / 1);
  --palette-gray-100: rgb(248 248 249 / 1);
  --palette-gray-1000: rgb(66 66 68 / 1);
  --palette-gray-1050: rgb(50 50 51 / 1);
  --palette-gray-1100: rgb(44 44 45 / 1);
  --palette-gray-1200: rgb(34 34 34 / 1);
  --palette-gray-1300: rgb(26 26 27 / 1);
  --palette-gray-1400: rgb(15 15 16 / 1);
  --palette-gray-200: rgb(237 237 240 / 1);
  --palette-gray-300: rgb(232 232 235 / 1);
  --palette-gray-400: rgb(228 229 233 / 1);
  --palette-gray-50: rgb(252 253 253 / 1);
  --palette-gray-500: rgb(219 220 225 / 1);
  --palette-gray-600: rgb(188 189 194 / 1);
  --palette-gray-700: rgb(153 154 157 / 1);
  --palette-gray-800: rgb(110 110 113 / 1);
  --palette-gray-900: rgb(88 88 90 / 1);
  --palette-gray-base: rgb(219 220 225 / 1);
  --palette-green-100: rgb(27 71 58 / 1);
  --palette-green-200: rgb(0 110 73 / 1);
  --palette-green-300: rgb(0 153 102 / 1);
  --palette-green-400: rgb(10 173 118 / 1);
  --palette-green-50: rgb(32 47 44 / 1);
  --palette-green-500: rgb(6 186 125 / 1);
  --palette-green-600: rgb(64 228 173 / 1);
  --palette-green-700: rgb(89 232 183 / 1);
  --palette-green-800: rgb(128 237 200 / 1);
  --palette-green-base: rgb(6 186 125 / 1);
  --palette-green-dark-100: rgb(30 49 43 / 1);
  --palette-green-dark-200: rgb(7 54 35 / 1);
  --palette-green-dark-300: rgb(9 75 48 / 1);
  --palette-green-dark-400: rgb(16 83 56 / 1);
  --palette-green-dark-50: rgb(33 39 38 / 1);
  --palette-green-dark-500: rgb(17 94 63 / 1);
  --palette-green-dark-600: rgb(74 144 116 / 1);
  --palette-green-dark-700: rgb(98 159 134 / 1);
  --palette-green-dark-800: rgb(134 181 162 / 1);
  --palette-green-dark-base: rgb(17 94 63 / 1);
  --palette-green-light-100: rgb(59 69 65 / 1);
  --palette-green-light-200: rgb(92 125 107 / 1);
  --palette-green-light-300: rgb(129 175 150 / 1);
  --palette-green-light-400: rgb(144 193 167 / 1);
  --palette-green-light-50: rgb(43 46 46 / 1);
  --palette-green-light-500: rgb(157 211 182 / 1);
  --palette-green-light-600: rgb(184 239 210 / 1);
  --palette-green-light-700: rgb(210 252 229 / 1);
  --palette-green-light-800: rgb(227 255 240 / 1);
  --palette-green-light-base: rgb(157 211 182 / 1);
  --palette-neutral-alpha-100: rgb(44 44 45 / 0.35);
  --palette-neutral-alpha-50: rgb(44 44 45 / 0.25);
  --palette-neutral-alpha-500: rgb(44 44 45 / 0.5);
  --palette-neutral-alpha-700: rgb(44 44 45 / 0.6);
  --palette-neutral-alpha-900: rgb(44 44 45 / 0.95);
  --palette-pink-100: rgb(70 49 58 / 1);
  --palette-pink-200: rgb(128 64 86 / 1);
  --palette-pink-300: rgb(178 90 120 / 1);
  --palette-pink-400: rgb(205 99 135 / 1);
  --palette-pink-50: rgb(47 39 44 / 1);
  --palette-pink-500: rgb(215 111 147 / 1);
  --palette-pink-600: rgb(219 123 156 / 1);
  --palette-pink-700: rgb(227 154 179 / 1);
  --palette-pink-800: rgb(235 183 201 / 1);
  --palette-pink-base: rgb(215 111 147 / 1);
  --palette-coral-100: rgb(70 39 40 / 1);
  --palette-coral-200: rgb(128 32 31 / 1);
  --palette-coral-300: rgb(178 45 43 / 1);
  --palette-coral-400: rgb(219 55 52 / 1);
  --palette-coral-50: rgb(47 36 37 / 1);
  --palette-coral-500: rgb(255 64 61 / 1);
  --palette-coral-600: rgb(255 112 110 / 1);
  --palette-coral-700: rgb(255 131 129 / 1);
  --palette-coral-800: rgb(235 157 155 / 1);
  --palette-coral-base: rgb(255 64 61 / 1);
  --palette-pink-light-100: rgb(78 64 70 / 1);
  --palette-pink-light-200: rgb(128 93 104 / 1);
  --palette-pink-light-300: rgb(178 129 146 / 1);
  --palette-pink-light-400: rgb(219 159 179 / 1);
  --palette-pink-light-50: rgb(49 45 48 / 1);
  --palette-pink-light-500: rgb(255 185 208 / 1);
  --palette-pink-light-600: rgb(255 198 216 / 1);
  --palette-pink-light-700: rgb(255 209 224 / 1);
  --palette-pink-light-800: rgb(255 220 231 / 1);
  --palette-pink-light-base: rgb(255 185 208 / 1);
  --palette-purple-100: rgb(53 54 70 / 1);
  --palette-purple-200: rgb(75 78 123 / 1);
  --palette-purple-300: rgb(105 109 171 / 1);
  --palette-purple-400: rgb(117 121 189 / 1);
  --palette-purple-50: rgb(41 41 48 / 1);
  --palette-purple-500: rgb(129 134 207 / 1);
  --palette-purple-600: rgb(176 181 247 / 1);
  --palette-purple-700: rgb(187 191 248 / 1);
  --palette-purple-800: rgb(192 195 231 / 1);
  --palette-purple-base: rgb(129 134 207 / 1);
  --palette-purple-dark-100: rgb(46 42 61 / 1);
  --palette-purple-dark-200: rgb(55 42 95 / 1);
  --palette-purple-dark-300: rgb(77 59 132 / 1);
  --palette-purple-dark-400: rgb(84 64 144 / 1);
  --palette-purple-dark-50: rgb(38 37 45 / 1);
  --palette-purple-dark-500: rgb(96 75 161 / 1);
  --palette-purple-dark-600: rgb(146 127 205 / 1);
  --palette-purple-dark-700: rgb(176 159 226 / 1);
  --palette-purple-dark-800: rgb(175 165 208 / 1);
  --palette-purple-dark-base: rgb(96 75 161 / 1);
  --palette-purple-light-100: rgb(63 63 72 / 1);
  --palette-purple-light-200: rgb(105 106 128 / 1);
  --palette-purple-light-300: rgb(146 148 178 / 1);
  --palette-purple-light-400: rgb(162 164 195 / 1);
  --palette-purple-light-50: rgb(44 44 49 / 1);
  --palette-purple-light-500: rgb(178 180 216 / 1);
  --palette-purple-light-600: rgb(221 223 255 / 1);
  --palette-purple-light-700: rgb(225 227 255 / 1);
  --palette-purple-light-800: rgb(232 233 255 / 1);
  --palette-purple-light-alpha-100: rgb(178 180 216 / 0.35);
  --palette-purple-light-alpha-50: rgb(178 180 216 / 0.25);
  --palette-purple-light-alpha-500: rgb(178 180 216 / 0.5);
  --palette-purple-light-alpha-700: rgb(178 180 216 / 0.6);
  --palette-purple-light-alpha-900: rgb(178 180 216 / 0.9);
  --palette-purple-light-base: rgb(178 180 216 / 1);
  --palette-white-alpha-100: rgb(255 255 255 / 0.05);
  --palette-white-alpha-1000: rgb(255 255 255 / 0.85);
  --palette-white-alpha-200: rgb(255 255 255 / 0.1);
  --palette-white-alpha-300: rgb(255 255 255 / 0.14);
  --palette-white-alpha-400: rgb(255 255 255 / 0.2);
  --palette-white-alpha-50: rgb(255 255 255 / 0.02);
  --palette-white-alpha-500: rgb(255 255 255 / 0.3);
  --palette-white-alpha-600: rgb(255 255 255 / 0.45);
  --palette-white-alpha-700: rgb(255 255 255 / 0.55);
  --palette-white-alpha-800: rgb(255 255 255 / 0.65);
  --palette-white-alpha-900: rgb(255 255 255 / 0.75);
  --palette-white-base: rgb(255 255 255 / 1);
  --palette-yellow-100: rgb(70 61 39 / 1);
  --palette-yellow-200: rgb(128 100 28 / 1);
  --palette-yellow-300: rgb(178 139 39 / 1);
  --palette-yellow-400: rgb(219 171 48 / 1);
  --palette-yellow-50: rgb(47 43 37 / 1);
  --palette-yellow-500: rgb(255 199 56 / 1);
  --palette-yellow-600: rgb(255 213 106 / 1);
  --palette-yellow-700: rgb(253 221 140 / 1);
  --palette-yellow-800: rgb(252 229 172 / 1);
  --palette-yellow-base: rgb(255 199 56 / 1);
  --palette-yellow-dark-100: rgb(68 48 31 / 1);
  --palette-yellow-dark-200: rgb(102 51 5 / 1);
  --palette-yellow-dark-300: rgb(143 71 7 / 1);
  --palette-yellow-dark-400: rgb(158 79 8 / 1);
  --palette-yellow-dark-50: rgb(46 39 34 / 1);
  --palette-yellow-dark-500: rgb(173 90 15 / 1);
  --palette-yellow-dark-600: rgb(217 140 71 / 1);
  --palette-yellow-dark-700: rgb(219 148 84 / 1);
  --palette-yellow-dark-800: rgb(229 178 132 / 1);
  --palette-yellow-dark-base: rgb(173 90 15 / 1);
  --palette-yellow-light-100: rgb(78 74 53 / 1);
  --palette-yellow-light-200: rgb(128 116 61 / 1);
  --palette-yellow-light-300: rgb(178 162 85 / 1);
  --palette-yellow-light-400: rgb(200 182 97 / 1);
  --palette-yellow-light-50: rgb(49 48 42 / 1);
  --palette-yellow-light-500: rgb(215 196 107 / 1);
  --palette-yellow-light-600: rgb(227 210 131 / 1);
  --palette-yellow-light-700: rgb(255 239 162 / 1);
  --palette-yellow-light-800: rgb(255 243 188 / 1);
  --palette-yellow-light-base: rgb(215 196 107 / 1);

  /* Framework colors */
  --color-bg: var(--palette-gray-1300);
  --color-bg-strong: var(--palette-gray-1400);
  --color-bg-overlay: var(--palette-black-alpha-700);
  --color-surface: var(--palette-gray-1200);
  --color-surface-hover: var(--palette-gray-1050);
  --color-surface-active: var(--palette-gray-1200);
  --color-surface-checked: var(--palette-purple-50);
  --color-surface-checked-hover: var(--palette-purple-light-200);
  --color-surface-raised: var(--palette-gray-1100);
  --color-surface-sunken: var(--palette-gray-1400);
  --color-surface-overlay: var(--palette-gray-1100);
  --color-surface-overlay-transparent: var(--palette-neutral-alpha-700);
  --color-surface-overlay-transparent-hover: var(--palette-neutral-alpha-900);
  --color-high-contrast: var(--palette-black-base);
  --color-border: var(--palette-gray-900);
  --color-border-weak: var(--palette-gray-1100);
  --color-border-weaker: var(--palette-gray-1200);
  --color-border-strong: var(--palette-gray-700);
  --color-border-brand-weakest: var(--palette-blue-100);
  --color-border-primary-weakest: var(--palette-gray-800);
  --color-border-negative-weakest: var(--palette-coral-100);
  --color-border-neutral-weakest: var(--palette-gray-900);
  --color-border-info-weakest: var(--palette-purple-light-100);
  --color-border-positive-weakest: var(--palette-green-100);
  --color-border-notice-weakest: var(--palette-yellow-100);
  --color-border-secondary-weakest: var(--palette-purple-dark-100);
  --color-border-disabled: var(--palette-gray-1100);
  --color-border-transparent: var(--palette-white-alpha-300);
  --color-brand: var(--palette-blue-500);
  --color-brand-weak: var(--palette-blue-200);
  --color-brand-weakest: var(--palette-blue-50);
  --color-text: var(--palette-gray-100);
  --color-text-link: var(--palette-white-base);
  --color-text-weak: var(--palette-white-alpha-800);
  --color-text-weaker: var(--palette-white-alpha-700);
  --color-text-negative: var(--palette-coral-700);
  --color-text-primary: var(--palette-gray-100);
  --color-text-disabled: var(--palette-gray-900);
  --color-on-brand: var(--palette-white-base);
  --color-on-brand-weak: var(--palette-blue-800);
  --color-on-brand-weakest: var(--palette-blue-800);
  --color-on-disabled: var(--palette-gray-700);
  --color-on-disabled-strong: var(--palette-gray-700);
  --color-on-info: var(--palette-white-base);
  --color-on-info-weak: var(--palette-purple-800);
  --color-on-info-weakest: var(--palette-purple-dark-800);
  --color-on-high-contrast: var(--palette-gray-100);
  --color-on-neutral: var(--palette-white-base);
  --color-on-neutral-weak: var(--palette-white-base);
  --color-on-neutral-weakest: var(--palette-white-base);
  --color-on-negative: var(--palette-white-base);
  --color-on-negative-weak: var(--palette-coral-800);
  --color-on-negative-weakest: var(--palette-coral-800);
  --color-on-notice: var(--palette-blue-dark-500);
  --color-on-notice-weak: var(--palette-yellow-700);
  --color-on-notice-weakest: var(--palette-yellow-800);
  --color-on-positive: var(--palette-blue-dark-500);
  --color-on-positive-weak: var(--palette-green-dark-800);
  --color-on-positive-weakest: var(--palette-green-dark-800);
  --color-on-primary: var(--palette-gray-1300);
  --color-on-primary-weak: var(--palette-gray-1300);
  --color-on-primary-weakest: var(--palette-gray-1300);
  --color-on-secondary: var(--palette-white-base);
  --color-on-secondary-weak: var(--palette-purple-800);
  --color-on-secondary-weakest: var(--palette-purple-800);
  --color-primary: var(--palette-gray-100);
  --color-primary-weak: var(--palette-gray-600);
  --color-primary-weakest: var(--palette-gray-700);
  --color-info: var(--palette-purple-dark-500);
  --color-info-weak: var(--palette-purple-light-200);
  --color-info-weakest: var(--palette-purple-dark-50);
  --color-neutral: var(--palette-gray-1000);
  --color-neutral-weak: var(--palette-gray-1100);
  --color-neutral-weakest: var(--palette-gray-1100);
  --color-negative: var(--palette-coral-600);
  --color-negative-weak: var(--palette-coral-200);
  --color-negative-weakest: var(--palette-coral-100);
  --color-positive: var(--palette-green-500);
  --color-positive-weak: var(--palette-green-100);
  --color-positive-weakest: var(--palette-green-light-50);
  --color-secondary: var(--palette-purple-dark-500);
  --color-secondary-weak: var(--palette-purple-light-100);
  --color-secondary-weakest: var(--palette-purple-50);
  --color-notice: var(--palette-yellow-500);
  --color-notice-weak: var(--palette-yellow-light-100);
  --color-notice-weakest: var(--palette-yellow-light-50);
  --color-disabled: var(--palette-gray-1000);
  --color-disabled-strong: var(--palette-gray-900);
  --color-interactive-focused: var(--palette-purple-light-alpha-100);
  --color-button-bg-brand: var(--palette-blue-500);
  --color-button-bg-brand-hover: var(--palette-blue-600);
  --color-button-bg-brand-active: var(--palette-blue-700);
  --color-button-bg-negative: var(--palette-coral-600);
  --color-button-bg-negative-hover: var(--palette-coral-300);
  --color-button-bg-negative-active: var(--palette-coral-200);
  --color-button-bg-negative-weak: var(--palette-coral-100);
  --color-button-bg-negative-weak-hover: var(--palette-coral-200);
  --color-button-bg-negative-weak-active: var(--palette-coral-300);
  --color-button-bg-outline-hover: var(--palette-gray-1000);
  --color-button-bg-outline-active: var(--palette-gray-900);
  --color-button-bg-outline-checked: var(--palette-purple-dark-100);
  --color-button-bg-outline-checked-hover: var(--palette-purple-100);
  --color-button-bg-outline-checked-active: var(--palette-purple-50);
  --color-button-bg-positive: var(--palette-green-500);
  --color-button-bg-positive-hover: var(--palette-green-400);
  --color-button-bg-positive-active: var(--palette-green-300);
  --color-button-bg-primary: var(--palette-gray-500);
  --color-button-bg-primary-hover: var(--palette-gray-600);
  --color-button-bg-primary-active: var(--palette-gray-700);
  --color-button-bg-primary-checked: var(--palette-purple-dark-700);
  --color-button-bg-primary-checked-hover: var(--palette-purple-dark-600);
  --color-button-bg-primary-checked-active: var(--palette-purple-dark-500);
  --color-button-bg-secondary-hover: var(--palette-gray-1000);
  --color-button-bg-secondary-active: var(--palette-gray-900);
  --color-button-bg-secondary-checked: var(--palette-purple-dark-100);
  --color-button-bg-secondary-checked-hover: var(--palette-purple-dark-200);
  --color-button-bg-secondary-checked-active: var(--palette-purple-dark-300);
  --color-button-bg-tertiary: var(--palette-gray-900);
  --color-button-bg-tertiary-hover: var(--palette-gray-1000);
  --color-button-bg-tertiary-active: var(--palette-gray-1100);
  --color-button-bg-tertiary-checked: var(--palette-purple-dark-700);
  --color-button-bg-tertiary-checked-hover: var(--palette-purple-dark-600);
  --color-button-bg-tertiary-checked-active: var(--palette-purple-dark-500);
  --color-button-bg-primary-reverse: var(--palette-gray-1200);
  --color-button-bg-primary-reverse-hover: var(--palette-gray-1300);
  --color-button-bg-primary-reverse-active: var(--palette-gray-1400);
  --color-button-bg-primary-reverse-checked: var(--palette-purple-dark-500);
  --color-button-bg-primary-reverse-checked-hover: var(
    --palette-purple-dark-300
  );
  --color-button-bg-primary-reverse-checked-active: var(
    --palette-purple-dark-200
  );
  --color-input-bg: var(--palette-gray-1100);
  --color-input-bg-active: var(--palette-gray-1200);
}

@media (prefers-color-scheme: dark) {
  .system-preference {
    @apply dark;
  }
}

/* Mood themes */
.primary {
  --color-bg: var(--palette-blue-500);
  --color-bg-strong: var(--palette-black-alpha-200);
  --color-surface: var(--palette-white-alpha-100);
  --color-surface-raised: var(--palette-white-alpha-200);
  --color-surface-sunken: var(--palette-black-alpha-200);
  --color-surface-overlay: var(--palette-white-alpha-100);
  --color-border: var(--palette-white-alpha-500);
  --color-border-weak: var(--palette-white-alpha-300);
  --color-border-weaker: var(--palette-white-alpha-300);
  --color-border-strong: var(--palette-white-alpha-600);
  --color-border-disabled: var(--palette-white-alpha-300);
  --color-on-brand: var(--palette-blue-800);
  --color-on-brand-weakest: var(--palette-white-base);
  --color-on-disabled: var(--palette-white-alpha-400);
  --color-on-disabled-strong: var(--palette-white-alpha-600);
  --color-on-primary: var(--palette-gray-1400);
  --color-primary: var(--palette-white-base);
  --color-primary-weakest: var(--palette-white-alpha-500);
  --color-disabled: var(--palette-white-alpha-100);
  --color-disabled-strong: var(--palette-white-alpha-200);
  --color-secondary: var(--palette-blue-800);
  --color-text: var(--palette-white-base);
  --color-text-weak: var(--palette-white-base);
  --color-text-weaker: var(--palette-white-base);
  --color-text-disabled: var(--palette-white-alpha-400);
  --color-text-link: var(--palette-white-base);
  --color-text-primary: var(--palette-white-base);
  --color-interactive-focused: var(--palette-white-alpha-700);
  --color-button-bg-outline-hover: var(--palette-black-alpha-200);
  --color-button-bg-outline-active: var(--palette-black-alpha-400);
  --color-button-bg-outline-checked: var(--palette-black-alpha-500);
  --color-button-bg-outline-checked-hover: var(--palette-black-alpha-600);
  --color-button-bg-outline-checked-active: var(--palette-black-alpha-700);
  --color-button-bg-brand: var(--palette-white-base);
  --color-button-bg-brand-hover: var(--palette-white-alpha-900);
  --color-button-bg-brand-active: var(--palette-white-alpha-700);
  --color-button-bg-primary: var(--palette-blue-50);
  --color-button-bg-primary-hover: var(--palette-blue-100);
  --color-button-bg-primary-active: var(--palette-blue-200);
  --color-button-bg-primary-checked: var(--palette-blue-800);
  --color-button-bg-primary-checked-hover: var(--palette-blue-800);
  --color-button-bg-primary-checked-active: var(--palette-blue-800);
  --color-button-bg-primary-reverse: var(--palette-blue-500);
  --color-button-bg-primary-reverse-hover: var(--palette-blue-600);
  --color-button-bg-primary-reverse-active: var(--palette-blue-700);
  --color-button-bg-primary-reverse-checked: var(--palette-blue-800);
  --color-button-bg-primary-reverse-checked-hover: var(--palette-blue-800);
  --color-button-bg-primary-reverse-checked-active: var(--palette-blue-800);
  --color-button-bg-secondary-hover: var(--palette-white-alpha-400);
  --color-button-bg-secondary-active: var(--palette-white-alpha-500);
  --color-button-bg-secondary-checked: var(--palette-black-alpha-500);
  --color-button-bg-secondary-checked-hover: var(--palette-black-alpha-600);
  --color-button-bg-secondary-checked-active: var(--palette-black-alpha-700);
  --color-button-bg-tertiary: var(--palette-blue-600);
  --color-button-bg-tertiary-hover: var(--palette-blue-700);
  --color-button-bg-tertiary-active: var(--palette-blue-800);
  --color-button-bg-tertiary-checked: var(--palette-blue-800);
  --color-button-bg-tertiary-checked-hover: var(--palette-blue-800);
  --color-button-bg-tertiary-checked-active: var(--palette-blue-800);
  --color-input-bg: var(--palette-white-alpha-300);
  --color-input-bg-active: var(--palette-blue-500);
}

.negative {
  --color-bg: var(--palette-coral-600);
  --color-bg-strong: var(--palette-black-alpha-200);
  --color-surface: var(--palette-white-alpha-100);
  --color-surface-raised: var(--palette-white-alpha-200);
  --color-surface-sunken: var(--palette-black-alpha-200);
  --color-surface-overlay: var(--palette-white-alpha-100);
  --color-border: var(--palette-white-alpha-500);
  --color-border-weak: var(--palette-white-alpha-300);
  --color-border-weaker: var(--palette-white-alpha-200);
  --color-border-strong: var(--palette-white-alpha-600);
  --color-border-disabled: var(--palette-white-alpha-300);
  --color-text: var(--palette-white-base);
  --color-text-weak: var(--palette-white-alpha-900);
  --color-text-weaker: var(--palette-white-alpha-800);
  --color-text-disabled: var(--palette-white-alpha-400);
  --color-text-link: var(--palette-white-base);
  --color-text-primary: var(--palette-white-base);
  --color-on-brand: var(--palette-gray-1400);
  --color-on-primary: var(--palette-coral-800);
  --color-on-brand-weakest: var(--palette-white-base);
  --color-on-disabled: var(--palette-white-alpha-400);
  --color-on-disabled-strong: var(--palette-white-alpha-600);
  --color-neutral: var(--palette-white-alpha-300);
  --color-neutral-weakest: var(--palette-white-alpha-200);
  --color-primary: var(--palette-white-base);
  --color-disabled: var(--palette-white-alpha-100);
  --color-disabled-strong: var(--palette-white-alpha-200);
  --color-on-neutral: var(--palette-white-base);
  --color-secondary: var(--palette-coral-800);
  --color-interactive-focused: var(--palette-white-alpha-700);
  --color-button-bg-brand: var(--palette-white-base);
  --color-button-bg-brand-hover: var(--palette-white-alpha-900);
  --color-button-bg-brand-active: var(--palette-white-alpha-700);
  --color-button-bg-outline-hover: var(--palette-black-alpha-200);
  --color-button-bg-outline-active: var(--palette-black-alpha-400);
  --color-button-bg-outline-checked: var(--palette-black-alpha-500);
  --color-button-bg-outline-checked-hover: var(--palette-black-alpha-600);
  --color-button-bg-outline-checked-active: var(--palette-black-alpha-700);
  --color-button-bg-primary: var(--palette-coral-50);
  --color-button-bg-primary-hover: var(--palette-coral-100);
  --color-button-bg-primary-active: var(--palette-coral-200);
  --color-button-bg-primary-checked: var(--palette-coral-800);
  --color-button-bg-primary-checked-hover: var(--palette-coral-800);
  --color-button-bg-primary-checked-active: var(--palette-coral-800);
  --color-button-bg-primary-reverse: var(--palette-coral-600);
  --color-button-bg-primary-reverse-hover: var(--palette-coral-700);
  --color-button-bg-primary-reverse-active: var(--palette-coral-800);
  --color-button-bg-primary-reverse-checked: var(--palette-coral-800);
  --color-button-bg-primary-reverse-checked-hover: var(--palette-coral-800);
  --color-button-bg-primary-reverse-checked-active: var(--palette-coral-800);
  --color-button-bg-secondary-hover: var(--palette-white-alpha-400);
  --color-button-bg-secondary-active: var(--palette-white-alpha-500);
  --color-button-bg-secondary-checked: var(--palette-black-alpha-500);
  --color-button-bg-secondary-checked-hover: var(--palette-black-alpha-600);
  --color-button-bg-secondary-checked-active: var(--palette-black-alpha-700);
  --color-button-bg-tertiary: var(--palette-coral-600);
  --color-button-bg-tertiary-hover: var(--palette-coral-700);
  --color-button-bg-tertiary-active: var(--palette-coral-800);
  --color-button-bg-tertiary-checked: var(--palette-coral-800);
  --color-button-bg-tertiary-checked-hover: var(--palette-coral-800);
  --color-button-bg-tertiary-checked-active: var(--palette-coral-800);
  --color-input-bg: var(--palette-white-alpha-300);
  --color-input-bg-active: var(--palette-coral-600);
}

.notice {
  --color-bg: var(--palette-yellow-500);
  --color-bg-strong: var(--palette-black-alpha-200);
  --color-surface: var(--palette-white-alpha-100);
  --color-surface-raised: var(--palette-white-alpha-200);
  --color-surface-sunken: var(--palette-black-alpha-200);
  --color-surface-overlay: var(--palette-white-alpha-100);
  --color-border: var(--palette-black-alpha-500);
  --color-border-weak: var(--palette-black-alpha-300);
  --color-border-weaker: var(--palette-black-alpha-200);
  --color-border-strong: var(--palette-black-alpha-600);
  --color-border-disabled: var(--palette-black-alpha-300);
  --color-text-weaker: var(--palette-black-alpha-800);
  --color-text-disabled: var(--palette-black-alpha-400);
  --color-text-link: var(--palette-black-base);
  --color-text-primary: var(--palette-black-base);
  --color-on-brand: var(--palette-white-base);
  --color-on-brand-weakest: var(--palette-black-base);
  --color-on-disabled: var(--palette-black-alpha-400);
  --color-on-disabled-strong: var(--palette-black-alpha-600);
  --color-on-primary: var(--palette-white-base);
  --color-neutral: var(--palette-black-alpha-300);
  --color-neutral-weakest: var(--palette-black-alpha-200);
  --color-primary: var(--palette-gray-1400);
  --color-disabled: var(--palette-black-alpha-100);
  --color-disabled-strong: var(--palette-black-alpha-200);
  --color-on-neutral: var(--palette-black-base);
  --color-secondary: var(--palette-gray-1400);
  --color-interactive-focused: var(--palette-black-alpha-700);
  --color-button-bg-outline-checked: var(--palette-yellow-200);
  --color-button-bg-outline-checked-hover: var(--palette-yellow-300);
  --color-button-bg-outline-checked-active: var(--palette-yellow-400);
  --color-button-bg-secondary-checked: var(--palette-yellow-200);
  --color-button-bg-secondary-checked-hover: var(--palette-yellow-300);
  --color-button-bg-secondary-checked-active: var(--palette-yellow-400);
  --color-input-bg: var(--palette-black-alpha-300);
  --color-input-bg-active: var(--palette-yellow-500);
}

.positive {
  --color-bg: var(--palette-green-500);
  --color-bg-strong: var(--palette-black-alpha-200);
  --color-surface: var(--palette-white-alpha-100);
  --color-surface-raised: var(--palette-white-alpha-200);
  --color-surface-sunken: var(--palette-black-alpha-200);
  --color-surface-overlay: var(--palette-white-alpha-100);
  --color-border: var(--palette-black-alpha-500);
  --color-border-weak: var(--palette-black-alpha-300);
  --color-border-weaker: var(--palette-black-alpha-300);
  --color-border-strong: var(--palette-black-alpha-600);
  --color-border-disabled: var(--palette-black-alpha-300);
  --color-text-disabled: var(--palette-black-alpha-400);
  --color-text-weaker: var(--palette-black-alpha-800);
  --color-text-link: var(--palette-black-base);
  --color-text-primary: var(--palette-black-base);
  --color-on-brand-weakest: var(--palette-black-base);
  --color-on-disabled: var(--palette-black-alpha-400);
  --color-on-disabled-strong: var(--palette-black-alpha-600);
  --color-on-primary: var(--palette-white-base);
  --color-neutral: var(--palette-black-alpha-300);
  --color-neutral-weakest: var(--palette-black-alpha-200);
  --color-primary: var(--palette-gray-1400);
  --color-disabled: var(--palette-black-alpha-100);
  --color-disabled-strong: var(--palette-black-alpha-200);
  --color-on-neutral: var(--palette-black-base);
  --color-secondary: var(--palette-gray-1400);
  --color-interactive-focused: var(--palette-black-alpha-700);
  --color-button-bg-outline-checked: var(--palette-green-200);
  --color-button-bg-outline-checked-hover: var(--palette-green-300);
  --color-button-bg-outline-checked-active: var(--palette-green-400);
  --color-button-bg-primary-checked: var(--palette-green-600);
  --color-button-bg-primary-checked-hover: var(--palette-green-700);
  --color-button-bg-primary-checked-active: var(--palette-green-800);
  --color-button-bg-primary-reverse-checked: var(--palette-green-600);
  --color-button-bg-primary-reverse-checked-hover: var(--palette-green-700);
  --color-button-bg-primary-reverse-checked-active: var(--palette-green-800);
  --color-button-bg-secondary-checked: var(--palette-green-200);
  --color-button-bg-secondary-checked-hover: var(--palette-green-300);
  --color-button-bg-secondary-checked-active: var(--palette-green-400);
  --color-button-bg-tertiary-checked: var(--palette-green-600);
  --color-button-bg-tertiary-checked-hover: var(--palette-green-700);
  --color-button-bg-tertiary-checked-active: var(--palette-green-800);
  --color-input-bg: var(--palette-black-alpha-300);
  --color-input-bg-active: var(--palette-green-500);
}

.info {
  --color-bg: var(--palette-purple-600);
  --color-bg-strong: var(--palette-black-alpha-200);
  --color-surface: var(--palette-white-alpha-100);
  --color-surface-raised: var(--palette-white-alpha-200);
  --color-surface-sunken: var(--palette-black-alpha-200);
  --color-surface-overlay: var(--palette-white-alpha-100);
  --color-border: var(--palette-white-alpha-500);
  --color-border-weak: var(--palette-white-alpha-300);
  --color-border-weaker: var(--palette-white-alpha-300);
  --color-border-strong: var(--palette-white-alpha-600);
  --color-border-disabled: var(--palette-white-alpha-300);
  --color-on-brand: var(--palette-gray-1400);
  --color-on-brand-weakest: var(--palette-white-base);
  --color-on-disabled: var(--palette-white-alpha-400);
  --color-on-disabled-strong: var(--palette-white-alpha-600);
  --color-neutral-weakest: var(--palette-black-alpha-200);
  --color-disabled: var(--palette-white-alpha-100);
  --color-disabled-strong: var(--palette-white-alpha-200);
  --color-secondary: var(--palette-gray-1400);
  --color-interactive-focused: var(--palette-white-alpha-700);
  --color-button-bg-brand: var(--palette-white-base);
  --color-button-bg-brand-hover: var(--palette-white-alpha-900);
  --color-button-bg-brand-active: var(--palette-white-alpha-700);
  --color-button-bg-outline-hover: var(--palette-white-alpha-200);
  --color-button-bg-outline-active: var(--palette-white-alpha-300);
  --color-button-bg-primary-checked: var(--palette-purple-800);
  --color-button-bg-primary-checked-hover: var(--palette-purple-800);
  --color-button-bg-primary-checked-active: var(--palette-purple-800);
  --color-button-bg-primary-reverse-checked: var(--palette-purple-800);
  --color-button-bg-primary-reverse-checked-hover: var(--palette-purple-800);
  --color-button-bg-primary-reverse-checked-active: var(--palette-purple-800);
  --color-button-bg-secondary-checked: var(--palette-white-alpha-200);
  --color-button-bg-secondary-checked-hover: var(--palette-white-alpha-300);
  --color-button-bg-secondary-checked-active: var(--palette-white-alpha-400);
  --color-button-bg-tertiary-checked: var(--palette-purple-800);
  --color-button-bg-tertiary-checked-hover: var(--palette-purple-800);
  --color-button-bg-tertiary-checked-active: var(--palette-purple-800);
  --color-input-bg: var(--palette-white-alpha-300);
  --color-input-bg-active: var(--palette-purple-600);
}

.neutral {
  --color-bg: var(--palette-gray-500);
  --color-bg-strong: var(--palette-black-alpha-200);
  --color-surface: var(--palette-white-alpha-100);
  --color-surface-raised: var(--palette-white-alpha-200);
  --color-surface-sunken: var(--palette-black-alpha-200);
  --color-surface-overlay: var(--palette-white-alpha-100);
  --color-border: var(--palette-black-alpha-500);
  --color-border-weak: var(--palette-black-alpha-300);
  --color-border-weaker: var(--palette-black-alpha-300);
  --color-border-strong: var(--palette-black-alpha-600);
  --color-border-disabled: var(--palette-black-alpha-300);
  --color-text-weak: var(--palette-black-alpha-900);
  --color-text-weaker: var(--palette-black-alpha-800);
  --color-text-disabled: var(--palette-black-alpha-400);
  --color-text-link: var(--palette-black-base);
  --color-text-primary: var(--palette-black-base);
  --color-on-brand-weakest: var(--palette-black-base);
  --color-on-disabled: var(--palette-black-alpha-400);
  --color-on-disabled-strong: var(--palette-black-alpha-600);
  --color-neutral-weakest: var(--palette-black-alpha-200);
  --color-primary: var(--palette-gray-1400);
  --color-primary-weakest: var(--palette-black-alpha-500);
  --color-neutral: var(--palette-gray-600);
  --color-disabled: var(--palette-black-alpha-100);
  --color-disabled-strong: var(--palette-black-alpha-200);
  --color-on-neutral: var(--palette-black-base);
  --color-secondary: var(--palette-gray-800);
  --color-interactive-focused: var(--palette-black-alpha-700);
  --color-input-bg: var(--palette-black-alpha-300);
  --color-input-bg-active: var(--palette-gray-500);
}
